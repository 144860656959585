export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31')
];

export const server_loads = [0];

export const dictionary = {
		"/": [~2],
		"/about": [~3],
		"/adminxyz": [~4],
		"/adminxyz/exam_boards": [~5],
		"/adminxyz/public_exam_disciplines": [~6],
		"/adminxyz/public_exam_position_roles": [~7],
		"/adminxyz/public_exams_areas": [~9],
		"/adminxyz/public_exams": [~8],
		"/adminxyz/user_requests": [~10],
		"/contact": [11],
		"/cycles/[objective_id]": [~12],
		"/dashboard": [~13],
		"/denied": [~14],
		"/disciplines/[objective_id]/[discipline_id]": [~15],
		"/dropdown": [16],
		"/forgot": [17],
		"/habits": [~18],
		"/import": [19],
		"/login": [~20],
		"/logout": [~21],
		"/objectives_old": [~23],
		"/objectives": [~22],
		"/signup": [24],
		"/sitemap": [25],
		"/study_history/[objective_id]/[discipline_id]/[topic_id]": [~26],
		"/subscriptions": [~27],
		"/test": [28],
		"/topics/[objective_id]/[discipline_id]": [~29],
		"/user_study_types/[objective_id]/[discipline_id]": [~30],
		"/users/[ddmmyyyy]": [~31]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';